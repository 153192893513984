@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: Nunito, sans-serif;
  background: #f5f6f7;
}

main {
  max-width: 30rem;
  margin: auto;
  padding: 2rem 1rem;
}

main > h1 {
  text-align: center;
}

.navButton {
  background-color: white;
  border: 2px solid lightseagreen;
  border-radius: 10%;
  color: lightseagreen;
  padding: 0.5% 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 1%;
  cursor: pointer;
}

.navButton:disabled {
  cursor: not-allowed;
}

.navButton:active, .navButton:hover, .navButton:focus {
  background-color: lightseagreen;
  border: 2px solid lightseagreen;
  border-radius: 10%;
  color: white;
  padding: 0.5% 2%;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 1%;
  cursor: pointer;
}


.Notification, .home {
  margin: 2rem auto;
  padding: 1rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.Notification input, select, textarea {
  display: block;
  margin: 1rem auto;
  width: 20rem;
  padding: 0.5rem;
  border: 1px solid #00b8e6;
  border-radius: 10px;
  outline: none;
}

.Notification input:focus, select:focus, textarea:focus {
  outline: 1px solid #022ffa;
}

.submitButton {
  width: 21rem;
  padding: 0.7rem;
  border: 1px solid #00b8e6;
  background: #00b8e6;
  color: #fff;
  border-radius: 20px;
  cursor: pointer;
  outline: none;
}

.submitButton:hover {
  background: #0093b8;
}

.submitButton:disabled {
  background: #d8d8d8;
  border: 1px solid #d8d8d8;
  cursor: not-allowed;
}

.uploader .MuiDropzoneArea-root {
  min-height: 200px;
  border: 1px solid lightseagreen;
}

.uploader {
  margin: 5% auto;
}

.loader {
  margin: 4% 42%;
}